<template>
  <div class="pad-container">
    <p class="c-9">关于我们介绍详情</p>
    <tinymce v-model="content" :height="300" />
    <div class="flx-row ali-c js-fe mt-50">
      <el-button class="form-button" type="primary" @click="save"
        >保存</el-button
      >
    </div>
  </div>
</template>
<script>
import Tinymce from "@/components/Tinymce";
import {
  sysAboutUsPut,
  sysAboutUsGet, 
} from "@/api/config_center/miniprogram_about";
export default {
  name: "miniprogram_about",
  components: {
    Tinymce,
  },
  data() {
    return {
      content: "",
      moduleId:""
    };
  },
  created() {
    this.getAbout();
  },
  methods: {
    getAbout() {
      sysAboutUsGet().then((res) => {
        // console.log(res.data.body)
        this.content = res.data.body.content;
        this.moduleId = res.data.body.id;
      });
    },
    save(e) {
      // console.log(e)
      let data = {
        id: this.moduleId,
        content: this.content,
      };
      // console.log(data)
      sysAboutUsPut(data).then((res) => {
        this.$message({
          message: "操作成功",
          type: "success",
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>

